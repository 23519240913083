import React from 'react';

const Footer = () => {
  return (
    <footer className=" text-gray-400 py-4 mt-8 footer">
      <div className="max-w-6xl mx-auto flex flex-col justify-between items-center px-4">

        <div className="flex space-x-4 mt-4 md:mt-0 border-margin">
          <a href="https://discord.gg/S8eRVXFdDv" target="_blank" rel="noopener noreferrer" className="hover:text-white">
            Сервер поддержки
          </a>
        </div>

        <div className="flex justify-center mt-4 md:mt-0 border-margin">
          &copy; 2024 Aiko® Все права защищены.
        </div>

      </div>
    </footer>
  );
};

export default Footer;
